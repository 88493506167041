html, body {
    height: 100%;
}

body {top: 0px;
left: 0px;

background: transparent linear-gradient(270deg, #C4CFE0 0%, #588BC5 18%, #0043A8 77%, #002073 100%) 0% 0% no-repeat padding-box;
opacity: 1;
}

.logo {
   
    background-image: url('./../assets/logo-idor.svg');
    background-size: contain;
    background-repeat: no-repeat;
    height: 100px;
    width: auto;
}

.login {
    display: flex;
    width: 100%;
    height: 100%;
}

.btn-login {
    background: #1F369A 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    color: #FFFFFF;
}