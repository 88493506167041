.home {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

header {
  flex: 0;
  background: #ffffff;
  .navbar {
    box-shadow: 0px 0px 5px 0px #00000030;
    color: #707070;
    a {
      text-decoration: none;
      color: #707070;
      &:hover {
        color: #808080;
      }
    }
    .user-name {
      font-size: 1.85rem;
    }
  }
}

main {
  overflow: hidden;
  flex: 1;
  background: #f0f1f2;
  & > div {
    background: #ffffff;
    padding-bottom: 1rem;
  }
  .actions {
    button {
      text-decoration: none;
      color: #707070;
      &:hover {
        transform: scale(1.2);
        color: #808080;
      }
    }
  }
}

#importFile {
  display: block;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;

  border: none;
  outline: none;
  background-color: #1f369a;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 4px;
  font-size: 1em;
  font-family: Arial, Helvetica, sans-serif;
}

// #homeDiv {
//   width: 100%;
//   // background: white;
//   height: 100%;
// }

.input {
  padding: 0 10px;
  height: 50px;
  background: #f0f1f2;
  border-radius: 15px;
  display: flex;
  align-items: center;
  i {
    color: #707070;
  }
  input {
    color: #707070;
    width: 100%;
    height: 50px;
    margin-left: 5px;
    border: none;
    background: transparent;
    outline: transparent;
  }
}

.select {
  padding: 0 10px;
  height: 50px;
  background: #f0f1f2;
  border-radius: 15px;
  display: flex;
  align-items: center;
  select {
    color: #707070;
    width: 100%;
    height: 50px;
    margin-left: 5px;
    border: none;
    background: transparent;
    outline: transparent;
  }
}

.date {
  padding: 0 10px;
  height: 50px;
  background: #f0f1f2;
  border-radius: 15px;
  display: flex;
  align-items: center;
  color: #707070 !important;
  i {
    cursor: pointer;
    color: #707070 !important;
  }
  .react-datepicker-wrapper {
    width: 0;
    margin-left: 5px;
  }

  .error-transmission{
    color: #D54A4C !important;
  }
}